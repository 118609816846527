import axios from 'axios';
import Cookies from 'js-cookie';
import router from '../router'
import store from '../store'
import NProgress from 'vue-nprogress'
const nprogress = new NProgress()


export const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 90000,
    headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    },
    withCredentials: true,
});


// Set withCredentials to true to ensure cookies are sent with cross-origin requests

async function fetchCsrfToken() {
    try {
        const response = await axios.get('https://apimma.payrollmaster.co.ke/api/v1/auth/get-csrf-token/',{withCredentials: true,}); // Replace with your Django endpoint
        return response.data.csrf_token;
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        throw error;
    }
}

instance.interceptors.request.use(async (config) => {
    nprogress.start();
    store.dispatch('Loading/updateLoading', true);

    const accessToken = Cookies.get('token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // Check if CSRF token is present
    let csrfToken = Cookies.get('csrftoken');

    // If CSRF token is not present, fetch it from Django
    if (!csrfToken) {
        try {
            csrfToken = await fetchCsrfToken();
            Cookies.set('csrftoken', csrfToken, { sameSite: 'strict' });
        } catch (error) {
            // Handle error or fallback logic
            console.error('Error fetching CSRF token:', error);
            throw error; // Ensure you handle this error appropriately in your application
        }
    }

    // Set CSRF token in the headers
    config.headers['X-CSRFToken'] = csrfToken;

    return config;
}, function (error) {
    nprogress.done();
    store.dispatch('Loading/updateLoading', false);
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    nprogress.done();
    store.dispatch('Loading/updateLoading', false);
    return response;
}, function (error) {
    nprogress.done();
    store.dispatch('Loading/updateLoading', false);

    if (error.response && error.response.status === 401 && error.response.config.url !== 'api/v1/auth/login/') {
        window.toast.fire({
            icon: 'error',
            title: 'Session timed out. Please login to continue.'
        });
        store.dispatch('Auth/logout');
        store.dispatch('User/logout');
        localStorage.setItem('returnUrl', router.currentRoute.path);
        router.push('/login');
    }

    return Promise.reject(error);
});


const api = {
    request(method, url, data, successMsg = null, errorMsg = null) {
        instance.request({
            url,
            data,
            method: method.toLowerCase(),
        }).then(successMsg).catch(errorMsg);
    },

    get(url, success = null, errorMsg = null) {
        return this.request('get', url, {}, success, errorMsg);
    },

    post(url, data, success = null, errorMsg = null) {
        return this.request('post', url, data, success, errorMsg);
    },

    put(url, data, success = null, errorMsg = null) {
        return this.request('put', url, data, success, errorMsg);
    },

    patch(url, data, success = null, errorMsg = null) {
        return this.request('patch', url, data, success, errorMsg);
    },

    delete(url, data = {}, success = null, errorMsg = null) {
        return this.request('delete', url, data, success, errorMsg);
    },
};

export default api;