import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import auction_routes from '../views/company/auction/router.js'
import store from '../store/index'
// import moment from 'moment'
//import company_prequal from "@/router/company/prequal";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/dashboards/DashBoard.vue'),
        meta: { requiresAuth: true, roles: true },
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboards/DashBoard.vue'),
        meta: { requiresAuth: true, roles: true },
    },

    {
        path: '/departments',
        name: 'DeptList',
        component: () => import('../views/hr_manager/department/DeptList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/department/update/:id',
        name: 'DeptUpdate',
        component: () => import('../views/hr_manager/department/DeptUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },

    {
        path: '/employees',
        name: 'EmployeeList',
        component: () => import('../views/hr_manager/employee/EmployeeList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/tasks',
        name: 'TasksList',
        component: () => import('../views/hr_manager/employee/tasks/tasks.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/my-tasks',
        name: 'MyTasksList',
        component: () => import('../views/hr_manager/employee/tasks/employeetasks.vue'),
        meta: { requiresAuth: true,},
    },
    {
        path: '/employee/create',
        name: 'EmployeeCreate',
        component: () => import('../views/hr_manager/employee/EmployeeCreate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/update/:id',
        name: 'EmployeeUpdate',
        component: () => import('../views/hr_manager/employee/EmployeeUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/details/:id/',
        name: 'EmployeeDetails',
        component: () => import('../views/hr_manager/employee/EmpDetails.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/other-deductions/:id/',
        name: 'EmployeeOtherDeductions',
        component: () => import('../views/hr_manager/deductions/other_deductions/OtherList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/ot/:id/',
        name: 'EmployeeOverTimes',
        component: () => import('../views/hr_manager/employee/ot/OtList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/perdiem/:id/',
        name: 'ListPerDiem',
        component: () => import('../views/hr_manager/employee/per_diem/ListPerDiem.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/payroll-list/:id/',
        name: 'PaySlipList',
        component: () => import('../views/hr_manager/employee/payslip/PaySlip.vue'),
        meta: { requiresAuth: true, roles: true },
    },
   
    {
        path: '/employee/single/payroll-list',
        name: 'PaySlipList',
        component: () => import('../views/hr_manager/employee/payslip/PaySlipEmployee.vue'),
        meta: { requiresAuth: true},
        
    },
    {
        path: '/employee/active-month/payslips/',
        name: 'PaySlipList',
        component: () => import('../views/hr_manager/employee/payslip/ViewCurrentMonthPayslips.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/payslip-generation-progress/:task_id/',
        name: 'EmployeePaySlipGenerationProgress',
        component: () => import('../views/hr_manager/employee/PaySlipGenerationProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/p9-form/:id/',
        name: 'P9FormList',
        component: () => import('../views/hr_manager/employee/p9_form/P9FormList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/employee/p9-form-generation-progress/:task_id/',
        name: 'P9FormGenerationProgress',
        component: () => import('../views/hr_manager/employee/p9_form/P9FormGenarationProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/payroll-generation-progress/:task_id/',
        name: 'PayRollGenerationProgress',
        component: () => import('../views/hr_manager/payroll/PayRollGenerationProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/currencies',
        name: 'CurrList',
        component: () => import('../views/hr_manager/currency/CurrList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/currencies/update/:id',
        name: 'CurrUpdate',
        component: () => import('../views/hr_manager/currency/CurrUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/leave-types',
        name: 'LeaveTypeList',
        component: () => import('../views/hr_manager/leave/LeaveTypeList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/leave-approvals',
        name: 'LeaveApproval',
        component: () => import('../views/hr_manager/leave/LeaveApproval.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/leave-applications',
        name: 'LeaveApproval',
        component: () => import('../views/hr_manager/leave/EmployeeLeaveApplications.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/employee-leave',
        name: 'EmployeeLeave',
        component: () => import('../views/hr_manager/leave/ApplyLeave.vue'),
        meta: { requiresAuth: true},
    },
    {
        path: '/appraisal-types',
        name: 'AppriasalTypeList',
        component: () => import('../views/hr_manager/appraisal/AppraisalTypeList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/leave-types/update/:id',
        name: 'LeaveTypeUpdate',
        component: () => import('../views/hr_manager/leave/LeaveTypeUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/deductions',
        name: 'DeductionList',
        component: () => import('../views/hr_manager/deductions/DeductionList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/deductions/update/:id',
        name: 'DeductionUpdate',
        component: () => import('../views/hr_manager/deductions/DeductionUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/relief-deductions',
        name: 'ReliefDeductionList',
        component: () => import('../views/hr_manager/deductions/relief/ReliefList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/relief-deductions/update/:id',
        name: 'ReliefUpdate',
        component: () => import('../views/hr_manager/deductions/relief/ReliefUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/allowance-types',
        name: 'AllowanceTypes',
        component: () => import('../views/hr_manager/allowance/AllowanceTypes.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/other-allowances/:id',
        name: 'OtherAllowances',
        component: () => import('../views/hr_manager/allowance/OtherAllowancesList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/allowance-types/update/:id',
        name: 'AllowanceUpdate',
        component: () => import('../views/hr_manager/allowance/AllowanceUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/insurance/:id',
        name: 'InsuranceList',
        component: () => import('../views/hr_manager/employee/insurance/InsuranceList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/insurance/update/:employeeId/:id',
        name: 'InsuranceUpdate',
        component: () => import('../views/hr_manager/employee/insurance/InsuranceUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/arrears/:id',
        name: 'ArrearsList',
        component: () => import('../views/hr_manager/employee/salary-arrears/ArrearsList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/arrears/update/:employeeId/:id',
        name: 'ArrearsUpdate',
        component: () => import('../views/hr_manager/employee/salary-arrears/ArrearsUpdate.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/loans',
        name: 'LoanList',
        component: () => import('../views/hr_manager/loan_management/LoanList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/payrolls',
        name: 'PayRollList',
        component: () => import('../views/hr_manager/payroll/PayRollList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/nssf-return-form',
        name: 'NssfReturnForm',
        component: () => import('../views/hr_manager/nssf_return_form/NssfList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/nssf-return-form-generation/:task_id/',
        name: 'NssfReturnFormGenerationProgress',
        component: () => import('../views/hr_manager/nssf_return_form/NssfGenerationProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/nhif-return-form',
        name: 'NhifReturnForm',
        component: () => import('../views/hr_manager/nhif_return_form/NhifList.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/nhif-return-form-generation/:task_id/',
        name: 'NhifReturnFormGenerationProgress',
        component: () => import('../views/hr_manager/nhif_return_form/NhifGenerationProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/upload-file',
        name: 'UploadFile',
        component: () => import('../views/hr_manager/banks/UploadBanks.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    
    {
        path: '/bank/branches/:id',
        name: 'BankBranches',
        component: () => import('../views/hr_manager/banks/BankBranches.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/import-progress/:task_id/',
        name: 'ImportProgress',
        component: () => import('../views/hr_manager/banks/ImportBanksProgress.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/reports',
        name: 'ReportList',
        component: () => import('../views/hr_manager/employee/Report.vue'),
        meta: { requiresAuth: true, roles: true },
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        redirect: () => {
        window.location.replace('https://apimma.payrollmaster.co.ke/api/v1/auth/reset_password/')
        }
        
    },
    {
        path: '/un-authorized',
        name: 'Unauthorized',
        component: () => import('../views/auth/Unauthorized.vue'),
    }, 
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue'),
    }, 
    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    document.title = 'mma Payroll System';
    const isAuthenticated = store.state.Auth.authUser !== null;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiredRoles = to.meta.roles;
   
  
   if (requiresAuth) {
    if (checkSessionExpiration()) {
        // Session has expired, perform cleanup actions and redirect to login page
        cleanupOnSessionExpiration();
        next({
          name: 'Login',
          query: {
            returnUrl: to.fullPath,
          },
        });
      }
      if (!isAuthenticated) {
        // Redirect to login page if authentication is required but the user is not logged in
        next({ name: 'Login', query: { returnUrl: to.fullPath } });
        // cleanupOnSessionExpiration();
      } else {
        // Update the last activity time in local storage
        updateLastActivityInLocalStorage();
  
        if (requiredRoles && !checkRoles(requiredRoles, store.state.Auth.authUser.user_permissions)) {
          // Redirect or handle unauthorized access
          next({ name: 'Unauthorized' });
        } else {
          // Continue to the requested route
          next();
        }
      }
    } else {
      // Continue to the requested route for routes that don't require authentication
      next();
    }
  });
  
  function checkSessionExpiration() {
    const lastActivity = localStorage.getItem('lastActivityTime');
   
    if (lastActivity) {
      const currentTime = new Date().getTime();
      const lastActivityTime = new Date(lastActivity).getTime();
      const sessionDuration = currentTime - lastActivityTime;
      const sessionExpired = sessionDuration > 3600000; // 1 hour in milliseconds
  
      return sessionExpired;
    }
    else{
        
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            
       
    }

  
    return false; // User or lastActivity property is not properly defined
  }
  
  function updateLastActivityInLocalStorage() {
    // Update the last activity time in local storage
    localStorage.setItem('lastActivityTime', new Date().toISOString());
  }
  
  function cleanupOnSessionExpiration() {
    // Session has expired, perform cleanup actions
    localStorage.removeItem('vuex'); // Adjust the key based on your Vuex store configuration
    localStorage.removeItem('lastActivityTime');
   
    
  }
  
  function checkRoles(requiredRoles) {
    
    const user = store.state.Auth.authUser;
    if (user && user.user_permissions ) {
      
        if (user.user_permissions === requiredRoles) {
          return true; // User has at least one of the required roles
       
      }
    }
  
    return ; // User or roles property is not properly defined or roles don't match
  }
 

 

export default router